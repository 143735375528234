import React, { useState, useContext, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Channel, Video } from '../types';
import { AuthContext } from '../auth/AuthContext';
import { Modal, Input } from 'antd';
import ListVideo from '../component/home/ListVideo';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

const ChannelContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
`;

const ChannelButtonAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #636D79;
  color: #636D79;
  background-color: transparent;
  cursor: pointer;
  height: 50px;
  width: 50px;
  transition: background 0.3s;

  font-size: 16px;
  font-weight: bold;

  &:hover {
    background: #ffffff;
  }
`;

const ScrollableListContainer = styled.div`
  flex: 1;
  background: #fff;
  border-radius: 25px;
  padding: 20px 30px;
  border: 1px solid #D2D6DB;
  overflow-y: auto;
  height: 100%;
`;

const ChannelImageContainer = styled.div`
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

const ChannelImage = styled.img<{ isSelected: boolean }>`
  border-radius: 50%;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: 3px solid ${props => props.isSelected ? '#89B9A1' : 'transparent'};
  transition: box-shadow 0.2s ease-out, border-color 0.2s ease-in;

  &:hover {
    box-shadow: ${props => props.isSelected ? '' : '0 0 20px rgba(0,0,0,0.4)'};
  }
`;

const DeleteChannel = styled.div`
  position: absolute;
  top: -5px; // Adjust based on visual needs
  right: -5px; // Adjust based on visual needs
  height: 20px;
  width: 20px;
  background: #FE5747;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;

  transition: background 0.3s;
  &:hover {
    background: #D63D2A;
  }
`;

const YoutubePage: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { myToken } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchError, setSearchError] = useState('');
  const [channelSelected, setChannelSelected] = useState<Channel | null>(null);

  const handleDeleteChannel = useCallback((channelId: number) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/api/channels/${channelId}`, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(() => {
      const currentChannelIndex = channels.findIndex((channel: Channel) => channel.id === channelId);
      const newChannels = channels.filter((channel: Channel) => channel.id !== channelId);
      setChannels(newChannels);

      if (newChannels.length === 0) {
        setChannelSelected(null);
      } else if (currentChannelIndex === 0) {
        setChannelSelected(newChannels[0]);
      } else {
        setChannelSelected(newChannels[Math.max(0, currentChannelIndex - 1)]);
      }
    }).catch(error => console.error('Error deleting channel:', error));
  }, [channels, myToken]);

  const fetchVideos = useCallback((channelId: string) => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/videos/${channelId}`, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(response => {
      const fetchedVideos = response.data.map((video: any) => ({
        videoId: video.video_id,
        title: video.title,
        publishedDate: video.published_date,
        description: video.description,
        channelTitle: video.channel_title,
        thumbnailUrl: video.thumbnail_url,
      }));
      setVideos(fetchedVideos);
    }).catch(error => console.error('Error fetching videos:', error));
  }, [myToken]);

  useEffect(() => {
    if (channelSelected) {
      fetchVideos(channelSelected.channelId);
    }
  }, [channelSelected, fetchVideos]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/user/channels`, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(response => {
      const fetchedChannels = response.data.map((channel: any) => ({
        id: channel.id,
        channelId: channel.channel_id,
        title: channel.title,
        description: channel.description,
        profile_picture_url: channel.profile_picture_url,
      }));
      if (fetchedChannels.length > 0) {
        setChannelSelected(fetchedChannels[0]);
      }
      setChannels(fetchedChannels);
    }).catch(error => console.error('Error fetching channels:', error));
  }, [myToken]);

  const handleSearch = useCallback(() => {
    if (!searchTerm) {
      setSearchError('Please enter a search term');
      return;
    }
    setSearchError('');
    axios.post(`${process.env.REACT_APP_API_URL}/api/channels/${searchTerm}`, {}, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(response => {
      setChannels([...channels, response.data]);
      setChannelSelected(response.data); // Select the newly added channel
      setIsModalOpen(false);
      window.location.reload(); // Reload the page after adding a channel
    }).catch(error => {
      console.error('Error adding channel:', error);
      setSearchError('Failed to add channel');
    });
  }, [channels, myToken, searchTerm]);

  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleOk = useCallback((e:any) => {
    e.preventDefault();
    handleSearch();
  }, [handleSearch]);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
    setSearchTerm('');
    setSearchError('');
  }, []);

  return (
    <Container>
      <Modal title="Add Channel" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Input placeholder="Enter channel name" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
        {searchError && <p style={{ color: 'red' }}>{searchError}</p>}
      </Modal>
      <ChannelContainer>
        <ChannelButtonAdd onClick={showModal}><PlusOutlined /></ChannelButtonAdd>
        {channels.map(channel => (
          <ChannelImageContainer key={channel.channelId} onClick={() => setChannelSelected(channel)}>
            <ChannelImage
              src={channel.profile_picture_url}
              alt={channel.title}
              isSelected={channel === channelSelected}
            />
            {channel === channelSelected && (
              <DeleteChannel onClick={() => handleDeleteChannel(channel.id)}>
                <CloseOutlined />
              </DeleteChannel>
            )}
          </ChannelImageContainer>
        ))}
      </ChannelContainer>
      <ScrollableListContainer>
        <ListVideo videos={videos} />
      </ScrollableListContainer>
    </Container>
  );
};

export default React.memo(YoutubePage);
