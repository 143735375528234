import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { AuthContext } from '../../auth/AuthContext';

import { Select } from 'antd';
import type { SelectProps } from 'antd';
import { List } from './List';
import { ArticleType } from '../../types';

const options: SelectProps['options'] = [];

for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const handleChange = (value: string[]) => {
  console.log(`selected ${value}`);
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

const ChannelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;


const ScrollableListContainer = styled.div`
  height: 100%;
  background: #fff;
  border-radius: 25px;
  padding: 20px 30px;
`;


type ArticleProps = {
  onAction: (article: ArticleType) => void;
};

export const ArticlesComponent: React.FC<ArticleProps> = ({ onAction }) => {
  const [articles, setArticles] = useState<ArticleType[]>([]);
  const { myToken } = useContext(AuthContext);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [keywords, setKeywords] = useState<SelectProps['options']>([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/keywords`, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${myToken}`
      }
    })
      .then(response => {
        const fetchedKeywords = response.data.map((keyword: string) => ({
          label: keyword,
          value: keyword
        }));
        setKeywords(fetchedKeywords);
      })
      .catch(error => console.error('Error fetching keywords:', error));
  }, []);

  const fetchArticlesByKeywords = (selectedKeywords: string[]) => {
    const keywordsParam = selectedKeywords.map(keyword => `keywords=${encodeURIComponent(keyword)}`).join('&');
    axios.get(`${process.env.REACT_APP_API_URL}/api/articles/by_keywords?${keywordsParam}`, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${myToken}`
      }
    })
      .then(response => {
        const fetchedArticles = response.data.map((article: any) => ({
          keyword: article.keyword,
          title: article.title,
          author: article.author || 'Unknown',
          url: article.url,
          description: article.description,
          imageUrl: article.url_to_image,
          publishedAt: article.published_at,
          content: article.content
        }));
        setArticles(fetchedArticles);
      })
      .catch(error => console.error('Error fetching articles:', error));
  };


  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
    fetchArticlesByKeywords(value);
  };

  return (
    <Container>
      <ChannelContainer>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          defaultValue={[]}
          onChange={handleChange}
          options={keywords}
        />
      </ChannelContainer>
      <ScrollableListContainer ref={listContainerRef}>
        <List articles={articles} containerHeight={400} onAction={onAction} />
      </ScrollableListContainer>
    </Container>
  );
};
