import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Channel, Video } from '../../types';
import { AuthContext } from '../../auth/AuthContext';
import { Button, Modal, Input } from 'antd';
import { ListVideo } from './List';
import {
  PlusOutlined,
  CloseOutlined
} from '@ant-design/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

const ChannelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ChannelButtonAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #636D79;
  color: #636D79;
  background-color: transparent;
  cursor: pointer;
  height: 50px;
  width: 50px;
  transition: background 0.3s;

  font-size: 16px;
  font-weight: bold;

  &:hover {
    background: #ffffff;
  }
`;

const ScrollableListContainer = styled.div`
  height: 100%;
  background: #fff;
  border-radius: 25px;
  padding: 20px 30px;
`;


const ChannelImageContainer = styled.div`
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

const ChannelImage = styled.img<{ isSelected: boolean }>`
  border-radius: 50%;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: 3px solid ${props => props.isSelected ? '#89B9A1' : 'transparent'};
  transition: box-shadow 0.2s ease-out, border-color 0.2s ease-in;

  &:hover {
    box-shadow: ${props => props.isSelected ? '' : '0 0 20px rgba(0,0,0,0.4)'};
  }
`;

const DeleteChannel = styled.div`
  position: absolute;
  top: -5px; // Adjust based on visual needs
  right: -5px; // Adjust based on visual needs
  height: 20px;
  width: 20px;
  background: #FE5747;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; // For potential icons or text
  font-size: 10px;

  transition: background 0.3s;
  &:hover {
    background: #D63D2A;
  }
`;

type YoutubeProps = {
  onAction: (video: Video) => void;
};


export const YoutubeComponent: React.FC<YoutubeProps> = ({onAction}) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { authUser, myToken } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchError, setSearchError] = useState('');
  const [channelSelected, setChannelSelected] = useState<Channel | null>(null);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);

  const handleDeleteChannel = (channelId: number) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/api/channels/${channelId}`, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(() => {
      const currentChannelIndex = channels.findIndex((channel:Channel) => channel.id === channelId);
      const newChannels = channels.filter((channel: Channel) => channel.id !== channelId);
      setChannels(newChannels);
  
      // Update the selected channel
      if (newChannels.length === 0) {
        setChannelSelected(null); // No channels left
      } else if (currentChannelIndex === 0) {
        setChannelSelected(newChannels[0]); // Select the first channel if the deleted one was the first
      } else {
        setChannelSelected(newChannels[Math.max(0, currentChannelIndex - 1)]); // Select the previous channel or the first if out of range
      }
    }).catch(error => console.error('Error deleting channel:', error));
  }

  useEffect(() => {
    const updateHeight = () => {
      if (listContainerRef.current) {
        const measurements = listContainerRef.current.getBoundingClientRect();
        setContainerHeight(measurements.height - 40);
        console.log(measurements.height)
      }
    };

    updateHeight(); // Update height initially
    window.addEventListener('resize', updateHeight); // Update height on resize

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [listContainerRef]);

  useEffect(() => {
    if (!channelSelected) return;
    axios.get(`${process.env.REACT_APP_API_URL}/api/videos/${channelSelected.channelId}`, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(response => {
      const fetchedVideos = response.data.map((video: any) => ({
        videoId: video.video_id,
        title: video.title,
        publishedDate: video.published_date,
        description: video.description,
        channelTitle: video.channel_title,
        thumbnailUrl: video.thumbnail_url,
      }));
      setVideos(fetchedVideos);
    }).catch(error => console.error('Error fetching videos:', error));
  }, [channelSelected]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/user/channels`, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(response => {
      console.log(response.data)
      const fetchedChannels = response.data.map((channel: any) => ({
        id: channel.id,
        channelId: channel.channel_id,
        title: channel.title,
        description: channel.description,
        profile_picture_url: channel.profile_picture_url,
      }));
      if (fetchedChannels.length > 0) {
        setChannelSelected(fetchedChannels[0]);
      }
      setChannels(fetchedChannels);
    }).catch(error => console.error('Error fetching channels:', error));
  }, []);



  const handleSearch = () => {
    if (!searchTerm) {
      setSearchError('Please enter a search term');
      return;
    }
    setSearchError('');
    axios.post(`${process.env.REACT_APP_API_URL}/api/channels/${searchTerm}`, {}, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    }).then(response => {
      setChannels([...channels, response.data]);
      setIsModalOpen(false); // Close the modal after adding
    }).catch(error => {
      console.error('Error adding channel:', error);
      setSearchError('Failed to add channel');
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    handleSearch();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSearchTerm(''); // Clear the search term on cancel
    setSearchError(''); // Clear any errors
  };
  return (
    <Container>
      <Modal title="Add Channel" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Input placeholder="Enter channel name" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
        {searchError && <p style={{ color: 'red' }}>{searchError}</p>}
      </Modal>
      <ChannelContainer>
        <ChannelButtonAdd onClick={showModal}><PlusOutlined /></ChannelButtonAdd>
        {channels.map(channel => (
          <ChannelImageContainer key={channel.channelId} onClick={() => setChannelSelected(channel)}>
            <ChannelImage
              src={channel.profile_picture_url}
              alt={channel.title}
              isSelected={channel === channelSelected}
            />
            {channel === channelSelected &&
              <DeleteChannel onClick={() => {handleDeleteChannel(channel.id)}}>
                <CloseOutlined />
              </DeleteChannel>
            }
          </ChannelImageContainer>
        ))}
      </ChannelContainer>
      <ScrollableListContainer ref={listContainerRef}>
        <ListVideo videos={videos} containerHeight={400} onAction={onAction}/>
      </ScrollableListContainer>
    </Container>
  );
};
