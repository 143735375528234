import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { Container, LeftContainer, RightContainer, ArticleContainer, RightFooter } from '../component/CreatePost/Styles';
import { Preview, Button, ButtonVariantEnum } from '../component/CreatePost/PreviewContainer';
import Idea from '../component/CreatePost/Idea';
import { ArticleType, FormValues, Video } from '../types';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const LoadingOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 52 }} spin />;

const CreatePost: React.FC = () => {
  const location = useLocation();
  const { myToken } = useContext(AuthContext);
  const [contentPost, setContentPost] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const initialStateRef = useRef(location.state);
  const [formValues, setFormValues] = useState<FormValues>({
    source: 'idea',
    topic: '',
    points: '',
    article: '',
    transcription: '',
    urlContent: '',
    style: 'mystyle',
    tone: '',
    pronoun: '',
    length: '',
    language: '',
    linkedinProfile: '',
  });

  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const [selectedArticle, setSelectedArticle] = useState<ArticleType | null>(null);

  useEffect(() => {
    const initialState = initialStateRef.current;

    if (initialState) {
      setFormValues(prevValues => ({
        ...prevValues,
        source: initialState?.type || 'idea',
        article: initialState?.type === 'article' ? initialState.data.content : '',
        transcription: initialState?.type === 'video' ? initialState.data.description : '',
      }));

      if (initialState?.type === 'video') {
        setSelectedVideo(initialState.data);
      } else if (initialState?.type === 'article') {
        setSelectedArticle(initialState.data);
      }
    }
  }, []);

  const handleGenerateClick = useCallback(async () => {
    if (formValues) {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${myToken}`
          },
          body: JSON.stringify(formValues)
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Erreur lors de la génération.');
        }

        const data = await response.json();
        setContentPost(data.linkedin_post);
      } catch (error: any) {
        messageApi.open({
          type: "error",
          content: error.message || "Erreur lors de la génération.",
        });
      } finally {
        setLoading(false);
      }
    }
  }, [formValues, myToken]);

  const handleFormChange = useCallback((changedValues: Partial<FormValues>, allValues: FormValues) => {
    setFormValues(allValues);
  }, []);

  const isValidUrl = useCallback((string: string) => {
    try {
      new URL(string);
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  const handlePost = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setLoading(true);

    if (contentPost) {
      let body = JSON.stringify({ content: contentPost });

      if (image) {
        body = JSON.stringify({
          content: contentPost,
          image: {
            url: "https://crewmate.gossin.xyz",
            urlToImage: image,
            description: "image description by postpilot",
            title: "image by postpilot",
          }
        });
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/linkedin/post/`, {
          method: 'POST',
          body: body,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${myToken}`,
          },
        });

        const result = await response.json();

        if (response.ok) {
          if (result.message && isValidUrl(result.message)) {
            window.open(result.message, '_blank');
          }
        } else {
          throw new Error(result.message || 'Échec de la publication');
        }
      } catch (error: any) {
        messageApi.open({
          type: "error",
          content: error.message || 'Erreur lors de la connexion au serveur',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [contentPost, image, isValidUrl, myToken]);

  return (
    <Container>
      {contextHolder}
      {loading && (
        <LoadingOverlay>
          <Spin indicator={antIcon} />
        </LoadingOverlay>
      )}
      <LeftContainer>
        <Preview
          loading={loading}
          contentPost={contentPost}
          setContentPost={setContentPost}
          image={image}
          setImage={setImage}
        />
      </LeftContainer>
      <RightContainer>
        <ArticleContainer>
          <Idea
            onGenerate={handleGenerateClick}
            onFormChange={handleFormChange}
            formValues={formValues}
            loading={loading}
            setLoading={setLoading}
            setImage={setImage}
            initialSelectedVideo={selectedVideo}
            initialSelectedArticle={selectedArticle}
          />
        </ArticleContainer>
        <RightFooter>
          <Button variant={ButtonVariantEnum.PRIMARY} onClick={handleGenerateClick}>Generate</Button>
          <Button variant={ButtonVariantEnum.PRIMARY} onClick={handlePost}>Post</Button>
        </RightFooter>
      </RightContainer>
    </Container>
  );
};

export default React.memo(CreatePost);
