import React, { FC } from "react";
import styled from "styled-components";
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import logoImage from '../src/assets/logo.png';

const MobileHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const Logo = styled.div`
  width: 42px;
  height: 42px;
  background: url(${logoImage}) no-repeat center center;
  background-size: contain;
`;

const Title = styled.div`
  font-size: 24px;
  color: #636D79;
  font-weight: 700;
  letter-spacing: 2px;
`;

interface MobileHeaderProps {
  onDrawerOpen: () => void;
}

export const MobileHeader: FC<MobileHeaderProps> = ({ onDrawerOpen }) => {
  return (
    <MobileHeaderContainer>
      <Logo />
      <Title>PostPilot</Title>
      <Button icon={<MenuOutlined />} onClick={onDrawerOpen} />
    </MobileHeaderContainer>
  );
};
