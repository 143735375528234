import React, { useState } from "react";
import styled from "styled-components";
import { message } from "antd";
import Button, { ButtonVariantEnum } from "../buttons/Button";
import { sendEmailClicked } from "./tools";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
  width: 100%;
`;

const Input = styled.input`
  padding: 16px 16px 15px 16px;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: #f5f5f5;
  color: #2c2c2c;
  font-size: 13.333px;
  font-weight: 600;
  line-height: normal;
  &:focus,
  &:active {
    outline: none;
  }
`;

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleSendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    await sendEmailClicked(email, messageApi);
  };

  return (
    <>
      {contextHolder}
      <Form onSubmit={handleSendEmail}>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
        />
        <Button variant={ButtonVariantEnum.PRIMARY}>Send Password Reset Email</Button>
      </Form>
    </>
  );
};

export default ForgotPassword;
