import React from "react";
import styled from "styled-components";
import { InfoCircleOutlined } from "@ant-design/icons";

const InputContainer = styled.div<{ emailExists?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  position: relative;
  background-color: "#ffe6e6";
  border: ${(p) => (p.emailExists ? "1px solid #FF6B6B" : "none")};
  border-radius: 10px;
`;

const CustomInput = styled.input`
  padding: 16px 16px 15px 16px;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: #f5f5f5;
  color: #2c2c2c;
  font-size: 13.333px;
  font-weight: 600;
  line-height: normal;
  &::placeholder {
    color: #2c2c2c;
  }
  &:focus,
  &:active {
    outline: none;
  }
`;

const Asterisk = styled.span`
  color: red;
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const InfoCircleOutlinedStyled = styled(InfoCircleOutlined)`
  font-size: 20px;
  color: #1890ff;
  cursor: pointer;
  &:hover {
    color: #40a9ff;
  }
`;

interface InputFieldProps {
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  emailExists?: boolean;
  required?: boolean;
  onInfoClick?: () => void;
}

const InputField: React.FC<InputFieldProps> = ({
  type,
  placeholder,
  value,
  onChange,
  emailExists = false,
  required = false,
  onInfoClick,
}) => {
  return (
    <InputContainer emailExists={emailExists}>
      {required && <Asterisk>*</Asterisk>}
      <CustomInput
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {onInfoClick && <InfoCircleOutlinedStyled onClick={onInfoClick} />}
    </InputContainer>
  );
};

export default InputField;
