import { Drawer } from 'antd';

import React, { FC, useState, useCallback } from "react";
import styled from "styled-components";
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import { MenuProps } from 'antd';
import {
    SettingFilled,
    PlusSquareOutlined,
    ReadFilled,
    FileTextFilled,
    YoutubeFilled
} from '@ant-design/icons';

const MyDrawer = styled(Drawer)`
`;

type CustomMenuItem = Required<MenuProps>['items'][number] & {
    url?: string;
    children?: CustomMenuItem[];
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    url?: string,
    children?: CustomMenuItem[]
): CustomMenuItem {
    return {
        key,
        icon,
        label,
        url,
        children
    } as CustomMenuItem;
}

const items: CustomMenuItem[] = [
    getItem('Create Post', '/', <PlusSquareOutlined />, '/'),
    getItem('Sources', '/sources', <ReadFilled />, undefined, [
        getItem('Articles', '/sources/articles', <FileTextFilled />, '/sources/articles'),
        getItem('Videos', '/sources/videos', <YoutubeFilled />, '/sources/videos'),
    ]),
    getItem('Settings', '/settings', <SettingFilled />, '/settings'),
];

interface MobileDrawerProps {
    visible: boolean;
    onClose: () => void;
}

export const MobileDrawer: FC<MobileDrawerProps> = ({ visible, onClose }) => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const selectedKeys = [location.pathname];

    const findItemByKey = useCallback((key: React.Key, items: CustomMenuItem[]): CustomMenuItem | undefined => {
        for (const item of items) {
            if (item.key === key) {
                return item;
            }
            if (item.children) {
                const foundChild = findItemByKey(key, item.children);
                if (foundChild) {
                    return foundChild;
                }
            }
        }
        return undefined;
    }, []);

    const onClick: MenuProps['onClick'] = useCallback((e: any) => {
        const item = findItemByKey(e.key, items);
        if (item && item.url) {
            navigate(item.url);
        }
    }, [findItemByKey, navigate]);

    return (
        <MyDrawer
            placement="top"
            closable={false}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ padding: 10 }}
            height={250}
        >
            <Menu theme="dark" selectedKeys={selectedKeys} mode="inline" items={items} onClick={onClick} />
        </MyDrawer>
    );
};
