import classNames from "classnames";
import React, { ButtonHTMLAttributes, forwardRef } from "react";
import { styled } from "styled-components";

/*
 * Types.
 */

export enum ButtonVariantEnum {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

/*
 * Props.
 */

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariantEnum;
}

const ButtonStyles = styled.button`
  display: flex;
  padding: 6px 10px 6px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #028CB5;
  border: none;
  gap:5px;

  color: #FFF;

  text-align: center;
  font-family: Poppins;
  font-weight: 600;

  cursor:pointer;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(2, 140, 181, 0.75);
  }

  @media (max-width: 1380px) {
    font-size: 0.8rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  height: 25px;
  width: 25px;
  padding: 5px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 999px;
  background: rgba(0, 0, 0, 0.10);
`;

/*
 * Component.
 */

const Button = forwardRef<HTMLButtonElement | null, ButtonProps>(
  function Button(props, ref) {
    const { children, className, variant, ...childProps } = props;

    const variantStyles = getVariantStyles(variant);

    return (
      <ButtonStyles
        {...childProps}
        ref={ref}
        className={classNames(className, variantStyles, "py-1")}
      >
        {children}
        {variant === ButtonVariantEnum.PRIMARY ? (
          <IconWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
              <path d="M3.125 7.5H11.875" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.5 3.125L11.875 7.5L7.5 11.875" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </IconWrapper>
        ) : null}
      </ButtonStyles>
    );
  }
);

/*
 * Helpers.
 */

function getVariantStyles(variant: ButtonVariantEnum) {
  switch (variant) {
    case ButtonVariantEnum.PRIMARY:
      return "text-white rounded-lg bg-ina-blue hover:bg-ina-blue/75 px-3";
    case ButtonVariantEnum.SECONDARY:
      return "text-gray-700 rounded-full hover:bg-gray-50 border-2 border-gray-100 px-4";
  }
}

export default React.memo(Button);
