import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Video } from '../../types';

// Styled components for the list and list items
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; 
  overflow-y: auto; 
  width: 100%;
  padding-right: 10px;

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  align-items: center;
  // box-shadow: 0 2px 8px rgba(0,0,0,0.1);

  cursor: pointer;

  transition: background 0.1s ease;

  &:hover{
      background: #fafafa;
  }
`;

const ItemImage = styled.img`
  height: 55px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ItemTitle = styled.h4`
  margin: 0;
  color: #333;
  font-size: 16px;
`;

const ItemDescription = styled.p`
  margin: 0;
  color: #666;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

// Props type definitions
type ListItemProps = {
  video: Video;
  onclick: () => void;
};



// ListItem component
const VideoItem: React.FC<ListItemProps> = ({ video, onclick }) => {
  const navigate = useNavigate();

  function tempsEcoule(dateStr: string): string {
    const date = new Date(dateStr);
    const maintenant = new Date();
    // Utiliser .getTime() pour obtenir des millisecondes et permettre l'opération arithmétique
    const diff = maintenant.getTime() - date.getTime(); // Différence en millisecondes

    const secondes = Math.floor(diff / 1000);
    const minutes = Math.floor(secondes / 60);
    const heures = Math.floor(minutes / 60);
    const jours = Math.floor(heures / 24);
    const mois = Math.floor(jours / 30);
    const annees = Math.floor(mois / 12);

    // Déclaration des types pour les paramètres de la fonction
    function pluralize(unit: number, word: string): string {
      return unit === 1 ? `il y a 1 ${word}` : `il y a ${unit} ${word}s`;
    }

    if (annees > 0) {
      return pluralize(annees, 'an');
    } else if (mois > 0) {
      return pluralize(mois, 'mois');
    } else if (jours > 0) {
      return pluralize(jours, 'jour');
    } else if (heures > 0) {
      return pluralize(heures, 'heure');
    } else if (minutes > 0) {
      return pluralize(minutes, 'minute');
    } else {
      return pluralize(secondes, 'seconde');
    }
  }

  return (
    <ListItemContainer onClick={onclick}>
      <ItemImage src={video.thumbnailUrl} alt="Article" />
      <ItemDetails>
        <ItemTitle>{video.title}</ItemTitle>
        <ItemDescription>{video.channelTitle} ⸱ {tempsEcoule(video.publishedDate)}</ItemDescription>
      </ItemDetails>
    </ListItemContainer>
  )
}

type ListProps = {
  videos: Video[];
  containerHeight: number;
  onAction: (video: Video) => void;
};

// List component
export const ListVideo: React.FC<ListProps> = ({ videos, containerHeight, onAction }) => (
  <ListContainer style={{ height: `${containerHeight}px` }}>
    {videos.map((video, index) => (
      <VideoItem
        key={index}
        video={video}
        onclick={() => {onAction(video)}}
      />
    ))}
  </ListContainer>
);