import React, { FormEvent, useState, useContext } from "react";
import styled from "styled-components";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { signUp } from "supertokens-auth-react/recipe/emailpassword";
import { doesEmailExist } from "supertokens-web-js/recipe/emailpassword";
import Button, { ButtonVariantEnum } from "../buttons/Button";
import { AuthContext } from "../../auth/AuthContext";
import Session from "supertokens-auth-react/recipe/session";
import InputField from "./InputField";
import InfoModals from "./InfoModals";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
  width: 100%;
`;

interface RegisterFormProps {
}

export const RegisterForm: React.FC<RegisterFormProps> = () => {
  const [email, setEmail] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [linkedinKey, setLinkedinKey] = useState("");
  const [openaiKey, setOpenaiKey] = useState("");
  const [infoLinkedinModalVisible, setInfoLinkedinModalVisible] = useState(false);
  const [infoOpenaiModalVisible, setInfoModalOpenaiVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { checkUser } = useContext(AuthContext);

  async function checkEmail(email: string) {
    try {
      let response = await doesEmailExist({ email });
      if (response.doesExist) {
        messageApi.open({
          type: "error",
          content: "Email already exists. Please sign in instead",
        });
        setEmailExists(true);
      } else {
        setEmailExists(false);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        messageApi.open({
          type: "error",
          content: err.message,
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Oops! Something went wrong.",
        });
      }
    }
  }

  const setEmailFunc = (e: string) => {
    setEmail(e);
    checkEmail(e);
  };

  const validateFields = () => {
    if (!email || !password || !confirmPassword) {
      messageApi.open({
        type: "error",
        content: "Veuillez remplir tous les champs obligatoires.",
      });
      return false;
    }
    if (password !== confirmPassword) {
      messageApi.open({
        type: "error",
        content: "Les mots de passe ne correspondent pas.",
      });
      return false;
    }
    return true;
  };

  const handleRegister = async (e: FormEvent) => {
    e.preventDefault();
    if (!validateFields()) return;

    try {
      const response = await signUp({
        formFields: [
          { id: "email", value: email },
          { id: "password", value: password },
        ],
      });

      if (response.status === "OK") {
        try {
          const accessTokenPayload = await Session.getAccessTokenPayloadSecurely();

          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessTokenPayload}`,
            },
            body: JSON.stringify({
              linkedin_key: linkedinKey,
              openai_api_key: openaiKey,
            }),
          });

          if (response.status === 200) {
            messageApi.open({
              type: "success",
              content: "Inscription réussie, vous pouvez maintenant vous connecter.",
            });
            setTimeout(() => {
              checkUser();
              navigate("/");
            }, 2000);
          }
        } catch (error: any) {
          messageApi.open({
            type: "error",
            content: error.response?.data?.detail || "Erreur lors de l'inscription. Veuillez réessayer.",
          });
          console.error("Registration error:", error.response?.data);
        }
      } else {
        messageApi.open({
          type: "error",
          content: "Une erreur est survenue lors de l'inscription. Veuillez réessayer.",
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Une erreur est survenue. Veuillez réessayer.",
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Form onSubmit={handleRegister}>
        <InputField
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmailFunc(e.target.value)}
          emailExists={emailExists}
          required
        />
        <InputField
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <InputField
          type="password"
          placeholder="Confirmer le mot de passe"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <InputField
          type="text"
          placeholder="Clé API LinkedIn"
          value={linkedinKey}
          onChange={(e) => setLinkedinKey(e.target.value)}
          onInfoClick={() => setInfoLinkedinModalVisible(true)}
        />
        <InputField
          type="text"
          placeholder="Clé API OpenAI"
          value={openaiKey}
          onChange={(e) => setOpenaiKey(e.target.value)}
          onInfoClick={() => setInfoModalOpenaiVisible(true)}
        />
        <Button variant={ButtonVariantEnum.PRIMARY}>Inscription</Button>
      </Form>
      <InfoModals
        infoLinkedinModalVisible={infoLinkedinModalVisible}
        setInfoLinkedinModalVisible={setInfoLinkedinModalVisible}
        infoOpenaiModalVisible={infoOpenaiModalVisible}
        setInfoModalOpenaiVisible={setInfoModalOpenaiVisible}
      />
    </>
  );
};

export default RegisterForm;
