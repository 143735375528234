import React, { FC, useState, useCallback } from "react";
import styled from "styled-components";
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import { MenuProps } from 'antd';
import {
  SettingFilled,
  PlusSquareOutlined,
  ReadFilled,
  FileTextFilled,
  YoutubeFilled
} from '@ant-design/icons';
import logoImage from '../src/assets/logo.png';
import { CreditStatus } from "./component/sider/CreditStatus";

const { Sider } = Layout;

type CustomMenuItem = Required<MenuProps>['items'][number] & {
  url?: string;
  children?: CustomMenuItem[];
};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  url?: string,
  children?: CustomMenuItem[]
): CustomMenuItem {
  return {
    key,
    icon,
    label,
    url,
    children
  } as CustomMenuItem;
}

const items: CustomMenuItem[] = [
  getItem('Create Post', '/', <PlusSquareOutlined />, '/'),
  getItem('Sources', '/sources', <ReadFilled />, undefined, [
    getItem('Articles', '/sources/articles', <FileTextFilled />, '/sources/articles'),
    getItem('Videos', '/sources/videos', <YoutubeFilled />, '/sources/videos'),
  ]),
  getItem('Settings', '/settings', <SettingFilled />, '/settings'),
];

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 248px;
  gap: 20px;
  background: #fff;
`;

const Space = styled.div`
  flex-grow: 1;
`;

const LogoStyled = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Logo = styled.div`
  width: 42px;
  height: 42px;
  background: url(${logoImage}) no-repeat center center;
  background-size: contain;
`;

const Title = styled.div`
  font-size: 24px;
  color: #636D79;
  font-weight: 700;
  letter-spacing: 2px;
`;

interface SiderComponentProps {
  isMobile?: boolean;
}

export const SiderComponent: FC<SiderComponentProps> = ({ isMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedKeys = [location.pathname];

  const findItemByKey = useCallback((key: React.Key, items: CustomMenuItem[]): CustomMenuItem | undefined => {
    for (const item of items) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const foundChild = findItemByKey(key, item.children);
        if (foundChild) {
          return foundChild;
        }
      }
    }
    return undefined;
  }, []);

  const onClick: MenuProps['onClick'] = useCallback((e: any) => {
    const item = findItemByKey(e.key, items);
    if (item && item.url) {
      navigate(item.url);
    }

  }, [findItemByKey, navigate]);

  return (
    // <Sider width={248}>
      <Container>
        <LogoStyled>
          <Logo />
          <Title>PostPilot</Title>
        </LogoStyled>
        <Menu theme="dark" selectedKeys={selectedKeys} mode="inline" items={items} onClick={onClick} />
        <Space />
        <CreditStatus />
      </Container>
    // </Sider>
  );
};
