import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Menu, Input, Select } from 'antd';
import { SignatureFilled, LinkedinFilled } from '@ant-design/icons';
import { FormValues } from '../../types';
import type { MenuProps } from 'antd';

const { Option } = Select;

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    label: 'My style',
    key: 'mystyle',
    icon: <SignatureFilled />
  },
  {
    label: 'Linkedin Profile',
    key: 'linkedin_profile',
    icon: <LinkedinFilled />,
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

interface StyleProps {
  onChange: (changedValues: Partial<FormValues>) => void;
}

const StyleComponent: React.FC<StyleProps> = ({ onChange }) => {
  const [current, setCurrent] = useState('mystyle');
  const [linkedinProfile, setLinkedinProfile] = useState('');

  const handleMenuChange: MenuProps['onClick'] = useCallback((e: { key: string }) => {
    setCurrent(e.key);
    onChange({ style: e.key });
  }, [onChange]);

  const handleToneChange = useCallback((value: string) => {
    onChange({ tone: value });
  }, [onChange]);

  const handlePronounChange = useCallback((value: string) => {
    onChange({ pronoun: value });
  }, [onChange]);

  const handleLengthChange = useCallback((value: string) => {
    onChange({ length: value });
  }, [onChange]);

  const handleLanguageChange = useCallback((value: string) => {
    onChange({ language: value });
  }, [onChange]);

  const handleLinkedinProfileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkedinProfile(e.target.value);
    onChange({ linkedinProfile: e.target.value });
  }, [onChange]);

  return (
    <Container>
      <Menu onClick={handleMenuChange} selectedKeys={[current]} mode="horizontal" items={items} />

      {current === 'mystyle' ? (
        <>
          <Select placeholder="Select the tone" onChange={handleToneChange}>
            <Option value="serious">Serious</Option>
            <Option value="humorous">Humorous</Option>
            <Option value="informative">Informative</Option>
            <Option value="inspirational">Inspirational</Option>
          </Select>
          <Select placeholder="Select the pronoun" onChange={handlePronounChange}>
            <Option value="je">Je</Option>
            <Option value="vous">Vous</Option>
            <Option value="nous">Nous</Option>
            <Option value="ils">Ils</Option>
          </Select>
        </>
      ) : (
        <Input placeholder="LinkedIn Profile URL" value={linkedinProfile} onChange={handleLinkedinProfileChange} />
      )}
      <Select placeholder="select the length" onChange={handleLengthChange}>
        <Option value="short">Short</Option>
        <Option value="medium">Medium</Option>
        <Option value="long">Long</Option>
      </Select>
      <Select placeholder="select the language" onChange={handleLanguageChange}>
        <Option value="french">French</Option>
        <Option value="english">English</Option>
      </Select>
    </Container>
  );
};

export default React.memo(StyleComponent);
