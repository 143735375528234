import React from "react";
import { Modal } from "antd";
import styled from "styled-components";
import openai1 from "../../assets/openai/openai-1.png";
import openai2 from "../../assets/openai/openai-2.png";
import openai3 from "../../assets/openai/openai-3.png";
import openai4 from "../../assets/openai/openai-4.png";
import link1 from "../../assets/linkedin/link-1.png";
import link2 from "../../assets/linkedin/link-2.png";
import link3 from "../../assets/linkedin/link-3.png";
import link4 from "../../assets/linkedin/link-4.png";
import link5 from "../../assets/linkedin/link-5.png";
import link6 from "../../assets/linkedin/link-6.png";
import link7 from "../../assets/linkedin/link-7.png";
import link8 from "../../assets/linkedin/link-8.png";
import link9 from "../../assets/linkedin/link-9.png";
import link10 from "../../assets/linkedin/link-10.png";
import link11 from "../../assets/linkedin/link-11.png";
import link12 from "../../assets/linkedin/link-12.png";

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
    height: auto;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  max-height: 500px;
  width: 100%;
  padding: 20px 5px;
  font-size: 16px;
  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200, 200, 200, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

interface InfoModalsProps {
  infoLinkedinModalVisible: boolean;
  setInfoLinkedinModalVisible: (visible: boolean) => void;
  infoOpenaiModalVisible: boolean;
  setInfoModalOpenaiVisible: (visible: boolean) => void;
}

const InfoModals: React.FC<InfoModalsProps> = ({
  infoLinkedinModalVisible,
  setInfoLinkedinModalVisible,
  infoOpenaiModalVisible,
  setInfoModalOpenaiVisible,
}) => {
  return (
    <>
      <Modal
        title="Information Clé API LinkedIn"
        centered
        visible={infoLinkedinModalVisible}
        onOk={() => setInfoLinkedinModalVisible(false)}
        onCancel={() => setInfoLinkedinModalVisible(false)}
        width={800}
      >
        <ModalContainer>
          <h2>Obtenir votre clé API LinkedIn</h2>
          <p>Pour obtenir votre clé API LinkedIn, suivez ces étapes simples :</p>
          <h3>Étape 1 : Connectez-vous à votre compte LinkedIn</h3>
          <p>📘 Connectez-vous à votre compte LinkedIn.</p>
          <h3>Étape 2 : Créer une page d'entreprise</h3>
          <ImageContainer>
            <img src={link1} alt="Étape 1" />
          </ImageContainer>
          <p>🏢 Allez dans la section <b>For Business</b> et sélectionnez <b>Create a Company Page</b>.</p>
          <h3>Étape 3 : Sélectionner le type de page</h3>
          <ImageContainer>
            <img src={link2} alt="Étape 2" />
          </ImageContainer>
          <p>🏢 Sélectionnez <b>Company</b> pour créer une page d'entreprise.</p>
          <h3>Étape 4 : Remplir les détails de l'entreprise</h3>
          <ImageContainer>
            <img src={link3} alt="Étape 3" />
          </ImageContainer>
          <p>📝 Remplissez les détails de votre entreprise et cliquez sur <b>Create page</b>.</p>
          <h3>Étape 5 : Accéder au portail développeur LinkedIn</h3>
          <ImageContainer>
            <img src={link4} alt="Étape 4" />
          </ImageContainer>
          <p>🔗 Allez sur <a href="https://developer.linkedin.com/" target="_blank" rel="noopener noreferrer">LinkedIn Developer Portal</a> et créez une nouvelle application.</p>
          <h3>Étape 6 : Configurer l'application</h3>
          <ImageContainer>
            <img src={link5} alt="Étape 5" />
          </ImageContainer>
          <p>🔑 Entrez les détails de votre application et associez-la à votre page d'entreprise.</p>
          <h3>Étape 7 : Configuration des produits API</h3>
          <ImageContainer>
            <img src={link6} alt="Étape 6" />
          </ImageContainer>
          <p>📦 Configurez les produits API dont vous avez besoin pour votre application.</p>
          <h3>Étape 7 : Configurer OAuth 2.0</h3>
          <ImageContainer>
            <img src={link7} alt="Étape 7" />
          </ImageContainer>
          <p>🔑 Configurez OAuth 2.0 en suivant les instructions fournies.</p>
          <h3>Étape 8 : Générer et copier la clé API</h3>
          <ImageContainer>
            <img src={link8} alt="Étape 8" />
          </ImageContainer>
          <p>🔑 Générer une nouvelle clé API et copiez-la pour l'utiliser dans votre application.</p>
          <ImageContainer>
            <img src={link9} alt="Étape 9" />
          </ImageContainer>
          <ImageContainer>
            <img src={link10} alt="Étape 10" />
          </ImageContainer>
          <h3>Étape 11 : Copier la clé API dans votre application</h3>
          <ImageContainer>
            <img src={link11} alt="Étape 11" />
          </ImageContainer>
          <p>📋 Copiez la clé API générée dans votre application pour commencer à l'utiliser.</p>
          <h3>Étape 12 : Coller la clé API dans l'application</h3>
          <ImageContainer>
            <img src={link12} alt="Étape 12" />
          </ImageContainer>
          <p>📥 Collez la clé API dans le champ approprié de l'application, comme illustré ci-dessous.</p>
        </ModalContainer>
      </Modal>

      <Modal
        title="Information Clé API OpenAI"
        centered
        visible={infoOpenaiModalVisible}
        onOk={() => setInfoModalOpenaiVisible(false)}
        onCancel={() => setInfoModalOpenaiVisible(false)}
        width={1000}
      >
        <ModalContainer>
          <h2>Obtenir votre clé API OpenAI</h2>
          <p>Pour obtenir votre clé API OpenAI, suivez ces étapes simples :</p>
          <h3>Étape 1 : Accédez à votre profil OpenAI</h3>
          <p>🔗 Rendez-vous sur la page de votre <a href="https://platform.openai.com/settings/profile?tab=api-keys" target="_blank" rel="noopener noreferrer">profil OpenAI</a>.</p>
          <h3>Étape 2 : Créer une nouvelle clé secrète</h3>
          <p>➕ Cliquez sur le bouton <b>"Create new secret key"</b>.</p>
          <ImageContainer>
            <img src={openai1} alt="Étape 1" />
          </ImageContainer>
          <h3>Étape 3 : Nommer votre clé API</h3>
          <p>📝 Entrez un nom pour votre clé API, puis cliquez sur <b>"Create secret key"</b> pour générer votre clé.</p>
          <ImageContainer>
            <img src={openai2} alt="Étape 2" />
          </ImageContainer>
          <h3>Étape 4 : Copier votre clé API</h3>
          <p>📋 Copiez votre clé API en cliquant sur le bouton <b>"Copy"</b>.</p>
          <ImageContainer>
            <img src={openai3} alt="Étape 3" />
          </ImageContainer>
          <ImageContainer>
            <img src={openai4} alt="Étape 4" />
          </ImageContainer>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default InfoModals;
