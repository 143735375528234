import { FC } from "react";
import styled from "styled-components";
import Button, { ButtonVariantEnum } from "../buttons/Button";
import { message } from 'antd';
import { sendEmailClicked } from "../AuthForm/tools";

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
`;

const Cell = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const TitleCell = styled.span`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 40%;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

const Columns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    width: 500px;
    height: 77px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const InputTitle = styled.div`
    color: #363636;
    font-size: 12.8px;
    font-weight: 400;
`;

const Display = styled.div`
    display: flex;
    padding: 16px 21px 16px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;
    font-size: 0.8rem;
    font-weight: 400;
`;

interface ProfileProps {
  authUser: any;
}

export const Profile: FC<ProfileProps> = ({ authUser }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const handlePasswordUpdate = async () => {
    sendEmailClicked(authUser.email, messageApi)
  };

  return (
    <Container>
      {contextHolder}
      {authUser ? (
        <>
          <Cell>
            <TitleCell>Identity</TitleCell>
            <Columns>
              <InputContainer>
                <InputTitle>Full Name</InputTitle>
                <Display>
                  {authUser.linkedin_name}
                </Display>
              </InputContainer>
              <InputContainer>
                <InputTitle>Email</InputTitle>
                <Display>
                  {authUser.email}
                </Display>
              </InputContainer>
            </Columns>
          </Cell>
          <Divider><Line /></Divider>
          <Cell>
            <TitleCell>Subscription Details</TitleCell>
            <Columns>
              <InputContainer>
                <InputTitle>Subscription Type</InputTitle>
                <Display>
                  {authUser.subscription_type}
                </Display>
              </InputContainer>
            </Columns>
          </Cell>
          <Divider><Line /></Divider>
          <Cell>
            <TitleCell>Password</TitleCell>
            <Columns>
              <InputContainer>
                <Button variant={ButtonVariantEnum.PRIMARY} onClick={handlePasswordUpdate}>
                  Send Password Reset Email
                </Button>
              </InputContainer>
            </Columns>
          </Cell>
        </>
      ) : null}
    </Container>
  );
};
