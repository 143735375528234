import React, { FC } from "react";
import styled from "styled-components";

/*
 * Props.
 */

interface TabsProps {
    selectTab: string;
    setSelectTab: React.Dispatch<React.SetStateAction<string>>;
    tabList: string[]
  }

interface TabProps {
  selected: boolean
}

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
`;

const Tab = styled.div<TabProps>`
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    ${(props) => (props.selected ? 'background: #F7F7F7;' : '')}
    color: ${(props) => (props.selected ? '#363636' : '#727272')};
    min-width: 70px;

    user-select: none; /* Standard syntax */
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */

    &:hover {
        background: #FBFBFB;
    }
`;

const Text = styled.span`
    font-size: 16px;
    font-weight: 400;
`;


export const Tabs: FC<TabsProps> = ({ selectTab, setSelectTab, tabList }) => {

  const onClick = (e: string) => {
    setSelectTab(e)
  }

  return (
    <Container>
      {tabList.map((e: string) => (
        <Tab key={e} selected={selectTab === e} onClick={() => onClick(e)}>
          <Text>{e}</Text>
        </Tab>
      ))}
    </Container>
  );
};
