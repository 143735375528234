import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useNavigate, useLocation } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authUser, setAuthUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { doesSessionExist, userId } = useSessionContext();
    const navigate = useNavigate();
    const location = useLocation();

    const checkUser = async () => {
        setIsLoading(true);

        if (!doesSessionExist) {
            setAuthUser(null);
            setIsLoading(false);
            return;
        }

        try {
            // console.log('Checking user with userId:', userId);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/check`, {
                headers: {
                    Authorization: `Bearer ${userId}`
                }
            });
            // console.log('API Response:', response.data);
            let user_link = {};

            try {
              const response_linkedin = await fetch(`${process.env.REACT_APP_API_URL}/api/linkedin/user/`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${userId}`
                }
              });
      
              if (!response_linkedin.ok) {
                throw new Error('Network response was not ok');
              }
      
              const data_linkedin = await response_linkedin.json();
              user_link = { linkedin_name: data_linkedin.name, profileImage: data_linkedin.picture };
            } catch (error) {
              console.error('Failed to fetch LinkedIn user:', error);
            }
      
            const mergedUser = { ...response.data, ...user_link };
            console.log('API mergedUser:', mergedUser);
            setAuthUser(mergedUser);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.error('User not found:', error);
            } else {
                console.error('An error occurred:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // console.log("check", userId, doesSessionExist)
        if (doesSessionExist && userId) {
            checkUser();
        }
        if (doesSessionExist === false) {
            if (!location.pathname.startsWith("/auth")) {
                navigate("/auth");
            }
        }
    }, [doesSessionExist, userId]);

    const logout = () => {
        setAuthUser(null);
    }

    return (
        <AuthContext.Provider value={{ authUser, isLoading, logout, checkUser, userId }}>
            {children}
        </AuthContext.Provider>
    );
};
