import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { message } from "antd";
import { submitNewPassword } from "supertokens-web-js/recipe/emailpassword";
import Button, { ButtonVariantEnum } from "../buttons/Button";
import { useSearchParams, useNavigate } from "react-router-dom";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
  width: 100%;
`;

const Input = styled.input`
  padding: 16px 16px 15px 16px;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: #f5f5f5;
  color: #2c2c2c;
  font-size: 13.333px;
  font-weight: 600;
  line-height: normal;
  &:focus,
  &:active {
    outline: none;
  }
`;

const NewPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      messageApi.open({
        type: "error",
        content: "Invalid or missing token.",
      });
      // navigate("/auth");
    }
  }, [token, messageApi, navigate]);

  const handleSubmitNewPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    await newPasswordEntered(newPassword);
  };

  const newPasswordEntered = async (newPassword: string) => {
    try {
      let response = await submitNewPassword({
        formFields: [{ id: "password", value: newPassword }],
      });

      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach((formField: any) => {
          if (formField.id === "password") {
            messageApi.open({
              type: "error",
              content: formField.error,
            });
          }
        });
      } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
        messageApi.open({
          type: "error",
          content: "Password reset failed. Please try again.",
        });
        // navigate("/auth");
      } else {
        messageApi.open({
          type: "success",
          content: "Password reset successful!",
        });
        navigate("/auth");
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        messageApi.open({
          type: "error",
          content: err.message,
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Oops! Something went wrong.",
        });
      }
    }
  };

  return (
    <>
      {contextHolder}
      <Form onSubmit={handleSubmitNewPassword}>
        <Input
          type="password"
          name="newPassword"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          autoComplete="new-password"
        />
        <Button variant={ButtonVariantEnum.PRIMARY}>Reset Password</Button>
      </Form>
    </>
  );
};

export default NewPassword;
