import React from "react";
import styled from "styled-components";

const StyledButtonMenu = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 8px 15.81px 8px 16px;
  align-items: flex-start;
  border-radius: 10px;
  cursor: pointer;

  text-align: center;
  font-size: 13.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: ${(p) => (p.isActive ? "#F5F5F5" : "transparent")};
  color: ${(p) => (p.isActive ? "#000" : "#4D4D4D")};
`;

interface ButtonMenuProps {
  isActive: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export const ButtonMenu: React.FC<ButtonMenuProps> = ({ isActive, onClick, children }) => {
  return (
    <StyledButtonMenu isActive={isActive} onClick={onClick}>
      {children}
    </StyledButtonMenu>
  );
};

