import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Header } from '../component/CreatePost/Header';
import { Footer } from '../component/CreatePost/Footer';
import { LoadingOutlined } from '@ant-design/icons';
import { Skeleton, Spin } from 'antd';
import Button, { ButtonVariantEnum } from '../component/buttons/Button';
import axios from 'axios';
import { List } from '../component/carousel/List';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100%;
  width: 100%;
  gap: 20px;
`;

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  width: 555px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e4e2df;
`;

const LeftContainer = styled.div`
  flex-shrink: 0;
  width: 567px;
  overflow-y: auto; // Allow scrolling

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const PostText = styled.div`
  width: 100%;
  font-size : 14px;
  font-weight: 400;
  fontfamily: apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.8);
  padding: 12px 16px 30px 16px;
  line-height: 20px;
  position: relative;
`;


const Space = styled.div`
  width: calc(100%-30px);
  height: 33px;
  margin: 0 15px;
  border-bottom:  1px solid #e4e2df;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  gap: 3px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  gap: 10px;
  position: relative;
`

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 8px;
  flex-grow: 1;
  border: 1px solid #e4e2df;
  overflow-y: auto; // Allow scrolling

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const RightFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
`

const ContentPost = styled.textarea`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  border: none;
  resize: none;
  overflow: hidden;
  &:focus {
    outline: none;
  }
`;


const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

type ArticleType = {
  title: string;
  description: string;
  imageUrl: string;
  selected: boolean;
};

function b64_to_utf8(str: string) {
  return decodeURIComponent(escape(window.atob(str)));
}

export const CreateCarousel: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [contentPost, setContentPost] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState<ArticleType[]>([]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [contentPost]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/articles/today`)
      .then(response => {
        const fetchedArticles = response.data.map((article: any) => ({
          title: article.title,
          description: article.description,
          imageUrl: article.urlToImage,
          selected: false
        }));
        setArticles(fetchedArticles);
      })
      .catch(error => console.error('Error fetching articles:', error));
  }, []);

  useEffect(() => {
    console.log(contentPost)
  }, [contentPost]);

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight);
      }
    };

    // Écouter les changements de taille au montage et à chaque redimensionnement
    updateSize();
    window.addEventListener('resize', updateSize);

    // Nettoyage de l'effet
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);


  const toggleSelected = (index: number) => {
    const newArticles = [...articles];
    newArticles[index].selected = !newArticles[index].selected;
    setArticles(newArticles);
  };

  const generatePDF = async () => {
    setLoading(true);
    const selectedArticles = articles.filter((article: any) => article.selected).map((article: any) => ({
      title: article.title,
      text: article.description,
      image_path: article.imageUrl
    }));
    if (selectedArticles.length === 0) {
      alert("Please select at least one article.");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/create-pdf/`, selectedArticles, {
        responseType: 'blob',
        headers: {
          'Accept': 'application/json'  // Explicitly stating what we accept back
        }
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(pdfUrl); // Enregistrer l'URL du PDF pour l'affichage
      const encodedMessage = response.headers['x-custom-message'];
      const decodedMessage = b64_to_utf8(encodedMessage);
      setContentPost(decodedMessage);
    } catch (error) {
      console.error('Error creating PDF:', error);
      alert("Failed to generate PDF.");
    }
    setLoading(false);
  };

  function isValidUrl(string: string) {
    try {
      new URL(string);
      return true;
    } catch (e) {
      return false;
    }
  }

  const postPDFToLinkedIn = async () => {
    if (!pdfUrl) {
      alert("Please generate a PDF first.");
      return;
    }
    if (!contentPost) {
      alert("Please enter some commentary text.");
      return;
    }

    // Assuming that the PDF is stored in a blob referenced by pdfUrl,
    // you need to retrieve the blob again to send it to the server.
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();

      const formData = new FormData();
      formData.append('file', blob, 'document.pdf');
      formData.append('text', contentPost);

      const postResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/post-pdf/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(postResponse);

      if (postResponse.status === 200) {
        const postUrl = postResponse.data.message;  // Capture the LinkedIn post URL from backend response
        if (postUrl && isValidUrl(postUrl)) {   // Assuming you have a utility function `isValidUrl`
          window.open(postUrl, '_blank');       // Open the LinkedIn post in a new tab
        }
      } else {
        throw new Error(postResponse.data.error || "Failed to post to LinkedIn.");
      }
    } catch (error) {
      console.error('Error posting to LinkedIn:', error);
      alert("Failed to post to LinkedIn.");
    }
  };

  return (
    <Container ref={containerRef}>
      <LeftContainer style={{ height: `${containerHeight}px` }}>
        <Preview>
          <Header />
          <PostText>
            {loading ?
              <Loader>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
              </Loader>
              :
              contentPost !== null ?
                <ContentPost
                  value={contentPost}
                  onChange={(e) => setContentPost(e.target.value)}
                  ref={textAreaRef}
                />
                :
                <Skeleton active />
            }
          </PostText>
          {pdfUrl ?
            <iframe src={pdfUrl} style={{ width: '100%', height: '500px' }} title="Render Carousel" frameBorder="0" />
            :
            <Skeleton.Image active style={{ width: "100%", height: "150px" }} />
          }
          <Space>
            <img src="https://static.licdn.com/aero-v1/sc/h/8ekq8gho1ruaf8i7f86vd1ftt" alt="like" data-test-reactions-icon-type="LIKE" data-test-reactions-icon-theme="light" /> 62
          </Space>
          <Footer />
        </Preview>
      </LeftContainer>
      <RightContainer style={{ height: `${containerHeight}px` }}>
        <ArticleContainer >
          <List articles={articles} onSelected={toggleSelected} />
        </ArticleContainer>
        <RightFooter>
          <Button variant={ButtonVariantEnum.PRIMARY} onClick={generatePDF}>
            Generate
          </Button>
          <Button variant={ButtonVariantEnum.PRIMARY} onClick={postPDFToLinkedIn}>
            Post
          </Button>
        </RightFooter>
      </RightContainer>
    </Container>
  );
};
