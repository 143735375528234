import React, { useEffect, useContext, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Layout, theme } from 'antd';
import { AuthContext } from "./auth/AuthContext";
import { signOut } from "supertokens-auth-react/recipe/session";
import {
    LogoutOutlined,
} from '@ant-design/icons';

const { Header } = Layout;

/*
 * Styles.
 */

const Component = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    align-items: center;
    height: 100%;
    padding: 0 50px;
    background: #FAFBFD;
`;

const User = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
`;

const HeaderTitle = styled.h1`
  font-size: 26px;
  color: #636D79;
  font-weight: 500;
  margin: 0;
  letter-spacing: 2px;
  flex-grow: 1;
`;

const HeaderButton = styled.button`
  background: #F6F6F6;
  border: 1px solid #9299A2;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #636D79;
  cursor: pointer;
  width: 51px;
  height: 51px;

  &:hover {
    background: #E9E9E9;
  }
`;

interface User {
    name: string;
    profileImage?: string;
}

const getTitle = (path: string) => {
    switch (path) {
        case '/sources/videos':
            return 'Youtube';
        case '/sources/articles':
            return 'Articles';
        case '/settings':
            return 'Settings';
        case '/':
            return 'Create Post';
        // case '/':
        //     return 'Overview';
        default:
            return '';
    }
};

const HeaderComponent: React.FC = () => {
    const navigate = useNavigate();
    const { token: { colorBgContainer } } = theme.useToken();
    const location = useLocation();
    const [title, setTitle] = useState(getTitle(location.pathname));

    const { authUser , logout} = useContext(AuthContext);

    useEffect(() => {
        setTitle(getTitle(location.pathname));
    }, [location.pathname]);

    const logOut =  useCallback(async() => {
        await signOut();
        logout()
        navigate("/auth");
    }, [ navigate]);

    return (
        <Header style={{ padding: 0, background: colorBgContainer }} >
            <Component>
                <HeaderTitle>{title}</HeaderTitle>
                <HeaderButton onClick={logOut}><LogoutOutlined /></HeaderButton>
            </Component>
        </Header>
    );
};

export default React.memo(HeaderComponent);
