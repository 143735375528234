import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ArticleType } from '../../types';

// Styled components for the list and list items
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; 
  overflow-y: auto; 
  width: 100%;
  padding-right: 10px;
  height: 400px;

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;

  cursor: pointer;

  transition: background 0.1s ease;

  &:hover{
      background: #fafafa;
  }
`;

const ItemImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px; // Space between the image and the text
  border-radius: 4px;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ItemTitle = styled.h4`
  margin: 0;
  color: #333;
  font-size: 16px;
`;

const ItemDescription = styled.p`
  margin: 0;
  color: #666;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

// Props type definitions

type ListItemProps = {
  article: ArticleType;
  onAction: () => void;
};

type ListProps = {
  articles: ArticleType[];
  containerHeight: number;
  onAction: (article: ArticleType) => void;
};

// ListItem component
const ListItem: React.FC<ListItemProps> = ({ article, onAction}) => {

  return (
    <ListItemContainer onClick={onAction}>
      <ItemImage src={article.imageUrl} alt="Article" />
      <ItemDetails>
        <ItemTitle>{article.title}</ItemTitle>
        <ItemDescription>{article.description}</ItemDescription>
      </ItemDetails>
    </ListItemContainer>
  )
}

// List component
export const List: React.FC<ListProps> = ({ articles, containerHeight, onAction }) => (
  <ListContainer style={{ height: `${containerHeight}px` }}>
    {articles.length > 0 ? (
      articles.map((article, index) => (
        <ListItem key={index} article={article} onAction={() => {onAction(article)}} />
      ))
    ) : (
      <div>No articles available</div>
    )}
  </ListContainer>
);