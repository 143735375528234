import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Settings } from './pages/Settings';
import { PrivateRoute } from './auth/PrivateRoute';
import { CreateCarousel } from './pages/CreateCarousel';
import YoutubePage from './pages/Youtube';
import CreatePost  from './pages/CreatePost';
import { ArticlesPage } from './pages/Articles';

import SuperTokens from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SuperTokensConfig } from "./config";
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';

SuperTokens.init(SuperTokensConfig);

export const RouterComponent: React.FC = () => {
  return (
    <Routes>
      {/* {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"), PreBuiltUIList)} */}
      <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
      <Route path="/auth" element={<LoginPage />} />
      <Route path="/" element={<PrivateRoute component={CreatePost} />} />
      <Route path="/carousel" element={<PrivateRoute component={CreateCarousel} />} />
      <Route path="/sources/videos" element={<PrivateRoute component={YoutubePage} />} />
      <Route path="/sources/articles" element={<PrivateRoute component={ArticlesPage} />} />
      <Route path="/settings" element={<PrivateRoute component={Settings} />} />
    </Routes>
  );
};
