import { sendPasswordResetEmail } from "supertokens-web-js/recipe/emailpassword";

export const sendEmailClicked = async (email: string, messageApi:any) => {
    try {
      let response = await sendPasswordResetEmail({
        formFields: [{ id: "email", value: email }],
      });

      if (response.status === "FIELD_ERROR") {
        // one of the input formFields failed validation
        response.formFields.forEach((formField:any) => {
          if (formField.id === "email") {
            // Email validation failed (for example incorrect email syntax).
            messageApi.open({
              type: "error",
              content: formField.error,
            });
          }
        });
      } else if (response.status === "PASSWORD_RESET_NOT_ALLOWED") {
        // this can happen due to automatic account linking. Please read our account linking docs
        messageApi.open({
          type: "error",
          content: "Password reset is not allowed.",
        });
      } else {
        // reset password email sent.
        messageApi.open({
          type: "success",
          content: "Please check your email for the password reset link.",
        });
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        messageApi.open({
          type: "error",
          content: err.message,
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Oops! Something went wrong.",
        });
      }
    }
  };