import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Button, {ButtonVariantEnum } from "../buttons/Button";


const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    gap: 25px;
    flex-direction: column;
     overflow-y: auto;
`;


const Cell = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const TitleCell = styled.span`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 40%;
`;

const Columns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    width: 500px;
    height: 77px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const InputTitle = styled.div`
    color: #363636;
    font-size: 12.8px;
    font-weight: 400;
    // margin-left: 20px;
`;

const Input = styled.input`
    display: flex;
    padding: 16px 21px 16px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;

    &:focus,
    &:active {
      outline: none;
    }
`;

interface SecretsProps {
    authUser: any;
    myToken: string;
    checkUser: any;
}

export const Secrets: React.FC<SecretsProps> = ({ authUser, myToken, checkUser }) => {
    const [openaikey, setOpenaikey] = useState<string>("");
    const [lnkkey, setLnkkey] = useState<string>("");

    useEffect(() => {
        if (authUser) {
            setOpenaikey(authUser.openai_api_key);
            setLnkkey(authUser.linkedin_key);
        }
    }, [authUser]);

    const handleUpdate = async () => {
        if (!authUser) {
            console.error('No authenticated user!');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/update_api_keys`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${myToken}`,
                },
                body: JSON.stringify({
                    linkedin_key: lnkkey,
                    openai_api_key: openaikey
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Update successful:', result);
            checkUser()
            alert('Keys updated successfully!');
        } catch (error) {
            console.error('Failed to update keys:', error);
            alert('Failed to update keys!');
        }
    };

    return (
        <Container>
            <Cell>
                <TitleCell>OpenAI</TitleCell>
                <Columns>
                    <InputContainer>
                        <InputTitle>API Key</InputTitle>
                        <Input
                            type="text"
                            placeholder=""
                            value={openaikey}
                            onChange={(e) => setOpenaikey(e.target.value)}
                        />
                    </InputContainer>
                </Columns>
            </Cell>
            <Cell>
                <TitleCell>Linkedin</TitleCell>
                <Columns>
                    <InputContainer>
                        <InputTitle>API Key</InputTitle>
                        <Input
                            type="text"
                            placeholder=""
                            value={lnkkey}
                            onChange={(e) => setLnkkey(e.target.value)}
                        />
                    </InputContainer>
                </Columns>
            </Cell>
            <Cell style={{ justifyContent: "flex-end", marginRight: "10%" }}>
                <Button variant={ButtonVariantEnum.PRIMARY} onClick={handleUpdate}>
                    Update
                </Button>
            </Cell>
        </Container>
    );
};
