import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import { Skeleton, Upload, message, Switch, Button as AntButton } from 'antd';
import { Header } from './Header';
import { Footer } from './Footer';
import Button, {ButtonVariantEnum } from '../buttons/Button';

const { Dragger } = Upload;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const PostText = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-family: apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.8);
  padding: 12px 16px 30px 16px;
  line-height: 20px;
  position: relative;
`;


const PostImage = styled.img`
  width: 100%;
`;

const Space = styled.div`
  width: calc(100%-30px);
  height: 33px;
  margin: 0 15px;
  border-bottom: 1px solid #e4e2df;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  gap: 3px;
`;

const SwitchStyled = styled(Switch)`
  width: 44px;
  height: 22px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  border: none;
  resize: none;
  overflow: hidden;
  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200, 200, 200, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

interface PreviewProps {
  loading: boolean;
  contentPost: string | null;
  setContentPost: (text: string | null) => void;
  image: string | null;
  setImage: (image: any) => void;
}

const Preview: React.FC<PreviewProps> = ({ loading, contentPost, setContentPost, image, setImage }) => {
  
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleImageUpload = (info: any) => {
    const file = info.fileList[0].originFileObj;
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target?.result as string);
      message.success(`${info.file.name} file uploaded successfully`);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setImage(null);
  };

  useEffect(() => {
    console.log('image:', image);
  }, [image]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [contentPost]);

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContentPost(e.target.value);
  };

  return (
    <PreviewContainer>
      <Header />
      <PostText>
        {loading ? (
          <Skeleton active />
        ) : contentPost !== null ? (
          <StyledTextArea
            ref={textAreaRef}
            value={contentPost}
            onChange={handleContentChange}
          />
        ) : (
          <Skeleton />
        )}
      </PostText>
      {image ? (
        <>
          <PostImage src={image} alt="img" />
          <AntButton onClick={handleImageRemove}>Remove Image</AntButton>
        </>
      ) : (
        <Dragger
          name="image"
          beforeUpload={() => false}
          onChange={handleImageUpload}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">Click or drag image to this area to upload</p>
          <p className="ant-upload-hint">
            Only support for single image upload. Strictly prohibited from uploading non-image files.
          </p>
        </Dragger>
      )}
      <Space>
        <img src="https://static.licdn.com/aero-v1/sc/h/8ekq8gho1ruaf8i7f86vd1ftt" alt="like" data-test-reactions-icon-type="LIKE" data-test-reactions-icon-theme="light" /> 62
      </Space>
      <Footer />
    </PreviewContainer>
  );
};

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  // width: 555px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e4e2df;
`;

export {
  Loader,
  PostText,
  PostImage,
  Space,
  SwitchStyled,
  Preview,
  Button,
  ButtonVariantEnum
};
