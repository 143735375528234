import React, { FormEvent, useState } from "react";
import styled from "styled-components";
import { message } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { signIn } from "supertokens-auth-react/recipe/emailpassword";

import Button, { ButtonVariantEnum } from "../buttons/Button";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
  width: 100%;
`;

const InputPassword = styled.div`
  width: 100%;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Input = styled.input`
  padding: 16px 16px 15px 16px;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: #f5f5f5;
  color: #2c2c2c;
  font-size: 13.333px;
  font-weight: 600;
  line-height: normal;
  &:focus,
  &:active {
    outline: none;
  }
`;

const ForgotPasswordLink = styled(Link)`
  color: #1890ff;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

export const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await signIn({ formFields: [{ id: "email", value: email }, { id: "password", value: password }] });

      if (response.status === "OK") {
        messageApi.open({
          type: "success",
          content: "Login successful."
        });
        navigate("/");
      } else if (response.status === "WRONG_CREDENTIALS_ERROR") {
        messageApi.open({
          type: "error",
          content: "Incorrect email or password."
        });
      } else if (response.status === "SIGN_IN_NOT_ALLOWED") {
        messageApi.open({
          type: "error",
          content: response.reason
        });
      } else if (response.status === "FIELD_ERROR") {
        response.formFields.forEach(formField => {
          if (formField.id === "email") {
            messageApi.open({
              type: "error",
              content: formField.error
            });
          }
        });
      } else {
        messageApi.open({
          type: "error",
          content: "An error occurred. Please try again."
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "An error occurred. Please try again."
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Form onSubmit={handleLogin}>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
        />
        <InputPassword>
          <Input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <ForgotPasswordLink to="/auth/forgot-password">Forgot Password?</ForgotPasswordLink>
        </InputPassword>
        <Button variant={ButtonVariantEnum.PRIMARY}>Connect</Button>
      </Form>
    </>
  );
};
