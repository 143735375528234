import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';


// Styled components for the list and list items
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; // Space between items
  margin-top: 20px; // Space below the preceding element
  width: 100%;
  padding: 10px;
`;

const ListItemContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;

  // cursor: pointer;

  // transition: background 0.1s ease;

  // &:hover{
  //     background: #fafafa;
  // }
`;

const ItemImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px; // Space between the image and the text
  border-radius: 4px;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ItemTitle = styled.h4`
  margin: 0;
  color: #333;
  font-size: 16px;
`;

const ItemDescription = styled.p`
  margin: 0;
  color: #666;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ItemSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid #e4e2df;

  transition: background 0.1s ease;

  &:hover{
      background: #fafafa;
  }

  cursor:pointer;
`;

const ItemSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 10px;
`;

// Props type definitions
type ArticleType = {
  title: string;
  description: string;
  imageUrl: string;
  selected: boolean;
};

type ListItemProps = {
  article: ArticleType;
  onSelected: () => void;
};

type ListProps = {
  articles: ArticleType[];
  onSelected: (index: number) => void;
};

// ListItem component
const ListItem: React.FC<ListItemProps> = ({ article, onSelected }) => {

  return (
    <ListItemContainer>
      <ItemSelectContainer><ItemSelect onClick={onSelected}>
        {article.selected ?
          <MinusOutlined />
          :
          <PlusOutlined />
        }
      </ItemSelect></ItemSelectContainer>
      <ItemImage src={article.imageUrl} alt="Article" />
      <ItemDetails>
        <ItemTitle>{article.title}</ItemTitle>
        <ItemDescription>{article.description}</ItemDescription>
      </ItemDetails>
    </ListItemContainer>
  )
}

// List component
export const List: React.FC<ListProps> = ({ articles, onSelected }) => (
  <ListContainer>
    {articles.map((article, index) => (
      <ListItem
        key={index}
        article={article}
        onSelected={() => onSelected(index)} // Pass the index to toggle specific article
      />
    ))}
  </ListContainer>
);