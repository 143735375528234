import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { SessionAuth } from "supertokens-auth-react/recipe/session";

export const PrivateRoute = ({ component: Component }) => {
  const { authUser, isLoading } = useContext(AuthContext);

  // console.log('PrivateRoute - AuthUser:', authUser);
  // console.log('PrivateRoute - isLoading:', isLoading);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return authUser ? (
    <SessionAuth>
      <Component />
    </SessionAuth>
  ) : (
    // <Navigate to="/create-user" />
    <Navigate to="/auth" />
  );
};