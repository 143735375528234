import React, { useState, useEffect, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { Input, message, Menu, Modal, Collapse } from 'antd';
import { FileTextFilled, BulbFilled, YoutubeFilled, CloudFilled, PlusOutlined } from '@ant-design/icons';
import { ArticleType, FormValues, Video } from '../../types';
import { YoutubeComponent } from '../youtube/Youtube';
import { YoutubeCard } from '../youtube/YoutubeCard';
import { AuthContext } from '../../auth/AuthContext';
import { ArticlesComponent } from '../articles/Articles';
import { ArticleCard } from '../articles/ArticleCard';
import type { MenuProps} from 'antd';
import type { SearchProps } from 'antd/es/input/Search';

const { Search } = Input;
const { TextArea } = Input;

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    label: 'Idea',
    key: 'idea',
    icon: <BulbFilled />
  },
  {
    label: 'Article',
    key: 'article',
    icon: <FileTextFilled />,
  },
  {
    label: 'Video',
    key: 'video',
    icon: <YoutubeFilled />,
  },
  {
    label: 'URL',
    key: 'url',
    icon: <CloudFilled />,
  },
];

const Choose = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  height: 77px;
  cursor: pointer;
  transition: background 0.1s ease;
  border: 1px solid #e4e2df;

  &:hover {
    background: #fafafa;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

const Transcription = styled.div`
  max-height: 200px;
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const LabelText = styled.div`
  font-size: 12px;
`;

interface SubjectProps {
  onChange: (changedValues: Partial<FormValues>) => void;
  setLoading: (b: boolean) => void;
  setImage: (image: any) => void;
  initialSelectedVideo: Video | null;
  initialSelectedArticle: ArticleType | null;
  formValues: FormValues;
}

const SubjectComponent: React.FC<SubjectProps> = ({ onChange, setLoading, setImage, initialSelectedVideo, initialSelectedArticle, formValues }) => {
  const [current, setCurrent] = useState('idea');
  const [transcription, setTranscription] = useState(initialSelectedVideo ? initialSelectedVideo.description : '');
  const [topic, setTopic] = useState('');
  const [points, setPoints] = useState('');
  const [contentUrl, setContentUrl] = useState('');
  const [openYoutube, setOpenYoutube] = useState(false);
  const [openArticle, setOpenArticle] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(initialSelectedVideo);
  const [selectedArticle, setSelectedArticle] = useState<ArticleType | null>(initialSelectedArticle);
  const { myToken } = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setSelectedVideo(initialSelectedVideo);
    setTranscription(initialSelectedVideo ? initialSelectedVideo.description : '');
    setCurrent(initialSelectedVideo ? 'video' : 'idea');
  }, [initialSelectedVideo]);

  useEffect(() => {
    setSelectedArticle(initialSelectedArticle);
    setCurrent(initialSelectedArticle ? 'article' : 'idea');
    setImage(initialSelectedArticle ? initialSelectedArticle.imageUrl : '');
  }, [initialSelectedArticle]);

  const fetchContent = useCallback(async (video_id: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transcripts/${video_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myToken}`
        },
      });

      if (!response.ok) throw new Error('Failed to fetch article content');
      const content_resp = await response.text();
      setTranscription(content_resp);
      onChange({ transcription: content_resp });
    } catch (error) {
      console.error('Error fetching article content:', error);
    }
  }, [myToken, onChange]);

  const handleMenuChange: MenuProps['onClick'] = useCallback((e: { key: string }) => {
    setCurrent(e.key);
    onChange({ source: e.key });
  }, [onChange]);

  useEffect(() => {
    if (selectedVideo && selectedVideo.videoId) {
      fetchContent(selectedVideo.videoId);
    }
  }, [selectedVideo]);

  const handleTopicChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTopic(e.target.value);
    onChange({ topic: e.target.value });
  }, [onChange]);

  const handlePointsChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPoints(e.target.value);
    onChange({ points: e.target.value });
  }, [onChange]);

  const onselectVideo = useCallback((video: Video) => {
    setSelectedVideo(video);
    setOpenYoutube(false);
  }, []);

  const onselectArticle = useCallback((article: ArticleType) => {
    setSelectedArticle(article);
    setImage(article.imageUrl);
    setOpenArticle(false);
  }, [setImage]);

  useEffect(() => {
    if (selectedArticle && selectedArticle.url) {
      onChange({ article: selectedArticle.content });
    }
  }, [selectedArticle]);

  const fetchContentFromURL = useCallback(async (url: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/fetch-text`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${myToken}`
        },
        body: JSON.stringify({ url }),
      });

      if (!response.ok) throw new Error('Failed to fetch content');
      const data = await response.json();
      return data.text;
    } catch (error) {
      console.error('Error fetching content:', error);
      messageApi.open({
        type: 'error',
        content: 'Failed to fetch content from URL',
      });
      return null;
    }
  }, [messageApi]);

  const onSearch: SearchProps['onSearch'] = useCallback(async (value: string) => {
    setLoading(true);
    const content = await fetchContentFromURL(value);
    if (content) {
      setContentUrl(content);
      onChange({ urlContent: content });
    }
    setLoading(false);
  }, [fetchContentFromURL, onChange, setLoading]);

  return (
    <Container>
      {contextHolder}
      <Modal
        title="Video"
        centered
        open={openYoutube}
        onOk={() => setOpenYoutube(false)}
        onCancel={() => setOpenYoutube(false)}
        width={1000}
      >
        <YoutubeComponent onAction={onselectVideo} />
      </Modal>

      <Modal
        title="Article"
        centered
        open={openArticle}
        onOk={() => setOpenArticle(false)}
        onCancel={() => setOpenArticle(false)}
        width={1000}
      >
        <ArticlesComponent onAction={onselectArticle} />
      </Modal>

      <Menu onClick={handleMenuChange} selectedKeys={[current]} mode="horizontal" items={items} />
      {current === 'idea' && (
        <>
          <Input placeholder="Sujet" value={topic} onChange={handleTopicChange} />
          <TextArea placeholder="Idées clées" rows={4} value={points} onChange={handlePointsChange} />
        </>
      )}
      {current === 'article' && (
        <>
          {selectedArticle ? (
            <>
              <ArticleCard article={selectedArticle} onClose={() => setSelectedArticle(null)} />
              <Collapse items={[{ key: '1', label: 'Article', children: <Transcription>{selectedArticle.content}</Transcription> }]} />
            </>
          ) : (
            <Choose onClick={() => setOpenArticle(true)}><PlusOutlined /></Choose>
          )}
        </>
      )}
      {current === 'video' && (
        <>
          {selectedVideo ? (
            <>
              <YoutubeCard video={selectedVideo} onClose={() => setSelectedVideo(null)} />
              <Collapse items={[{ key: '1', label: 'Transcription', children: <Transcription>{transcription}</Transcription> }]} />
            </>
          ) : (
            <Choose onClick={() => setOpenYoutube(true)}><PlusOutlined /></Choose>
          )}
        </>
      )}
      {current === 'url' && (
        <>
          <LabelText>Types d'URL pris en charge (site web, vidéo YouTube, article de presse/blog, publication LinkedIn, ...)</LabelText>
          <Search placeholder="https://www.papillesetpupilles.fr/2024/06/courgettes-au-lait-de-coco-et-au-curry.html/" onSearch={onSearch} enterButton />
          {contentUrl !== "" ? (
            <Collapse items={[{ key: '1', label: 'Contenu', children: <Transcription>{contentUrl}</Transcription> }]} />
          ) : null}
        </>
      )}
    </Container>
  );
};

export default React.memo(SubjectComponent);
